<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <div class="w-full text-center" v-else-if="isLoading">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <template v-else>
      <asom-card>
        <asom-alert variant="warning" v-if="canUpdateHealthDeclaration && isDeclarationEmpty">
          There is no declaration for this station yet. Please click on the button
          "Update" to start a new one.
        </asom-alert>
        <asom-form-field label="Station">
          <span>{{ stationName }}</span>
        </asom-form-field>
        <asom-form-field
          label="Fitness Observation Details"
          :state="inputStates('formData.declaration')"
          error="Confirmation cannot be empty"
        >
          <div
            id="text-holder"
            class="py-4 rounded-md"
            v-if="!updateMode"
            v-dompurify-html="formattedDeclaration"
            >
          </div>
          <div class="pt-4" v-else>
            <asom-input-text-editor
              v-model="formData.declaration"
            />
          </div>
        </asom-form-field>
        <div
          v-if="canUpdateHealthDeclaration"
          class="col-span-2 flex justify-end space-x-4 pt-8">
          <asom-button
            v-if="updateMode"
            variant="secondary"
            text="Cancel Update"
            @click="updateMode = false"
          />
          <asom-button
            v-if="!updateMode"
            text="Update"
            @click="startUpdateMode"
          />
          <asom-button
            v-else
            text="Confirm Update"
            @click="updateDeclaration"
            :loading="isSubmitting"
            :disabled="isSubmitting"
          />
        </div>
      </asom-card>
    </template>
  </div>
</template>
<script>
import get from "lodash.get";
import isEmpty from "lodash.isempty";
import { required } from "@vuelidate/validators";
import { mapGetters } from "vuex";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import {
  GET_HEALTH_DECLARATION,
  UPDATE_HEALTH_DECLARATION,
} from "../../../constants/apis";
import { getAsync, postAsync } from "../../../services/serviceHandlers";

export default {
  setup: () => ({ v$: useVuelidate() }),
  mixins: [inputStates],
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      error: null,
      declaration: null,
      formData: {
        declaration: null,
      },
      updateMode: false,
    };
  },
  validations() {
    return {
      formData: {
        declaration: { required },
      },
    };
  },
  mounted() {
    this.loadDeclaration();
  },
  computed: {
    ...mapGetters({
      isBelongToCCL: "auth/isBelongToCCL",
      isSMCR: "auth/isSMCR",
      stationId: "selectedStation/id",
      lineId: "auth/userLineId",
      stationName: "selectedStation/name",
      canUpdate: "auth/canUpdateHealthDeclaration"
    }),
    isDeclarationEmpty() {
      return isEmpty(this.declaration);
    },
    formattedDeclaration() {
      return !isEmpty(this.declaration) ? this.declaration : 'This station does not have a health declaration.'
    },
    canUpdateHealthDeclaration() {
      return (this.isSMCR && !this.isBelongToCCL) || this.canUpdate;
    },
  },
  methods: {
    async loadDeclaration() {
      this.isLoading = true;
      this.error = null;
      const response = await getAsync({
        url: GET_HEALTH_DECLARATION,
        params: {
          stationId: this.stationId,
          lineId: this.lineId,
        },
      });
      if (response.success) {
        this.declaration = get(response, "payload.data");
      } else {
        this.error = response.payload;
      }
      this.isLoading = false;
    },
    updateDeclaration() {
      if (this.isSubmitting) return;
      this.error = null;
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.onSubmit();
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    async onSubmit() {
      this.isSubmitting = true;
      const result = await postAsync({
        url: UPDATE_HEALTH_DECLARATION,
        body: {
          healthDeclaration: this.formData.declaration,
          stationId: this.stationId,
          lineId: this.lineId,
        },
      });
      if (result.success) {
        this.isSubmitting = false;
        this.updateMode = false;
        this.loadDeclaration();
      } else {
        this.isSubmitting = false;
        this.error = result.payload;
        this.$scrollTop();
      }
    },
    startUpdateMode() {
      this.updateMode = true;
      this.formData.declaration = this.declaration || "";
    },
  },
};
</script>

<style lang="scss">
#text-holder{
  white-space: pre;

  .ql-size-huge{
    font-size:xx-large;
  }
  .ql-size-large{
    font-size:x-large;
  }
  .ql-size-small{
    font-size:small;
  }
  ol {
    list-style-type: number;
    list-style-position: inside;
    padding-left: 1rem;
  }
  ul {
    list-style-type: disc;
    list-style-position: inside;
    padding-left: 1rem;
  }
}
</style>
